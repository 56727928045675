<template>
  <div class="row justify-content-md-center">
    <div class="screen" :class="previewClass">
      <div v-if="formFinished" class="formFinished">
        <h1 v-if="formFinishedMessage">{{ formFinishedMessage }}</h1>
        <h1 v-else>{{ 'forms.formSubmitted' | translate }}</h1>
        <button class="close-form" @click="closeForm">{{ 'forms.closeForm' | translate }}</button>
      </div>
      <div v-else>
        <div class="breadcrums-container float-right">
          <div v-for="(section, index) in sections" :key="index">
            <div class="section" v-if="index !== selectedSection">{{ index + 1 }}</div>
            <select
              v-else
              name
              id
              :value="selectedSection"
              class="select-section"
              @change="checkAllowedChangeSection($event)"
            >
              <option
                v-for="(section, index) in sections"
                :key="index"
                :value="index"
                :disabled="isSectionDisabled(index) || loading"
              >
                {{ index + 1 }} / {{ sections }}
              </option>
            </select>
          </div>
        </div>
        <form :class="{ submitInvalid: submitInvalid }">
          <vue-form-json-schema
            v-model="model"
            :schema="formSchema"
            :ui-schema="formUiSchema"
            v-on:state-change="onChangeState"
            v-on:validated="onValidated"
          />
        </form>
        <div class="form-group p-2 float-left" v-if="sections > 0 && selectedSection > 0">
          <button :disabled="loading" class="btn btn-primary" @click="goToPreviousSection">
            <span v-if="loading">
              <loader></loader>
            </span>
            <span v-else>{{ $t('general.back') }}</span>
          </button>
        </div>
        <div class="form-group p-2 float-right" v-if="sections > 0">
          <button :disabled="loading" type="submit" class="btn btn-primary" @click="handleNextSection">
            <span v-if="loading">
              <loader></loader>
            </span>
            <span v-else>{{ selectedSection === sections - 1 ? $t('general.send') : $t('quiz.next') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import FormAddSectionMarker from '@forms/components/form-items/sections/FormAddSectionMarker.vue';
import VueFormJsonSchema from 'vue-form-json-schema';
import SettingsPanel from '@forms/components/settings-panel/SettingsPanel.vue';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import fieldTypes from '@forms/const/fieldTypes.js';

import buildUISchema from '@forms/mixins/uischema.js';
import planhatMixin from '../../../../src/app/mixins/planhat';
import Vue from 'vue';

var Ajv = require('ajv');

export default {
  components: {
    VueFormJsonSchema,
    FormAddSectionMarker,
    SettingsPanel
  },
  mixins: [planhatMixin],
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      model: {},
      parsedModel: {},
      selectedSection: 0,
      formSchema: {},
      formFinished: false,
      valid: false,
      submitInvalid: false,
      formUiSchema: [],
      largestCompletedSection: 0,
      ajv: null,
      savedLocally: false,
      repeatableValuesModel: {},
      repeatableInterval: null,
      formFinishedMessage: ''
    };
  },
  created() {
    this.ajv = new Ajv({ allErrors: true });
    if (this.form) {
      // For onboardee
      this.formSchema = this.form.versions[this.form.versions.length - 1].schema;

      let createRepeteableInterval = false;
      Object.keys(this.formSchema.properties).forEach((section) => {
        if (this.formSchema.properties[section] && this.formSchema.properties[section].properties) {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (
              this.formSchema.properties[section].properties[key]['field-type'] === fieldTypes.group &&
              !this.formSchema.properties[section].properties[key].maxItems
            ) {
              createRepeteableInterval = true;
            }
          });
        }
      });

      if (createRepeteableInterval) {
        this.repeatableInterval = setInterval(() => {
          this.setRepeatebleValues();
        }, 8000);
      }

      // DETECT browserclose to save values
      window.addEventListener('beforeunload', this.saveLocalFormValues);

      if (this.form.versions.length > 0) {
        // Previously Submitted, thus populate model
        let submittedData = {};
        if (this.form.formSubmit) {
          const formSubmitData = this.form.formSubmit.data;
          Object.keys(formSubmitData).forEach((keySection) => {
            Object.keys(formSubmitData[keySection]).forEach((keyQuestion) => {
              let values = {};

              if (keyQuestion.indexOf('form-group') > -1 && !this.isRepeatableGroup(keyQuestion)) {
                submittedData[keyQuestion] = formSubmitData[keySection][keyQuestion];
                formSubmitData[keySection][keyQuestion].forEach((groupQuestions) => {
                  Object.keys(groupQuestions).forEach((keyQuestionInGroup) => {
                    const key = keyQuestionInGroup.split('.').pop();
                    values[key] = groupQuestions[keyQuestionInGroup];
                    if (this.requiresValidation(keySection, keyQuestionInGroup, keyQuestion)) {
                      values[`${key}/confirm`] = values[key];
                    }
                  });
                });
                submittedData[keyQuestion] = values;
              } else {
                submittedData[keyQuestion] = formSubmitData[keySection][keyQuestion];
                if (this.requiresValidation(keySection, keyQuestion)) {
                  submittedData[`${keyQuestion}/confirm`] = formSubmitData[keySection][keyQuestion];
                }
              }
            });
          });
          this.largestCompletedSection = Object.keys(formSubmitData).length;
        } else if (this.localFormStorageOnboardee) {
          // Locally stored values
          var formsStoredData = this.localFormStorageOnboardee;
          if (formsStoredData) {
            let form = formsStoredData.find((formStoredData) => formStoredData.id === this.form.id);
            if (form) {
              let allQuestions = {};
              const lastForm = this.form.versions[this.form.versions.length - 1].schema.properties;
              if (this.form.versions[this.form.versions.length - 1].schema.properties) {
                Object.keys(lastForm).forEach((section) => {
                  if (lastForm[section].properties) {
                    Object.keys(lastForm[section].properties).forEach((question) => {
                      allQuestions[question] = lastForm[section].properties[question];
                    });
                  }
                });
              }
              Object.keys(form.values).map((key) => {
                if (key.indexOf('form-group') > -1) {
                  Object.keys(form.values[key]).map((keyInGroup) => {
                    if (
                      typeof form.values[key][keyInGroup] !== 'object' &&
                      this.IsJsonString(form.values[key][keyInGroup])
                    ) {
                      form.values[key][keyInGroup] = JSON.parse(form.values[key][keyInGroup]);
                    }
                  });
                } else {
                  if (typeof form.values[key] !== 'object' && this.IsJsonString(form.values[key])) {
                    if (allQuestions[key] && allQuestions[key]['field-type']) {
                      switch (allQuestions[key]['field-type']) {
                        case fieldTypes.questionPhone:
                        case fieldTypes.questionOpenAnswer:
                          form.values[key] = String(form.values[key]);
                          break;
                        default:
                          form.values[key] = JSON.parse(form.values[key]);
                          break;
                      }
                    } else {
                      form.values[key] = JSON.parse(form.values[key]);
                    }
                  }
                }
              });

              submittedData = form.values;
            }
          }
        }

        Vue.set(this, 'model', submittedData);
        this.formUiSchema = buildUISchema(
          { form: this.form.versions[this.form.versions.length - 1] },
          false,
          submittedData
        );
      }
    } else {
      // For admin preview test
      this.formSchema = this.schema;
      this.formUiSchema = this.uiSchema;
    }
  },
  mounted() {
    this.formFinishedMessage = this.formSchema.data ? this.formSchema.data.finalMessage : '';
    setTimeout(() => {
      this.displaySelectedSection();
      this.addTargetBlankToFormLinks();
    }, 600);
  },
  methods: {
    addTargetBlankToFormLinks() {
      // Go over all links in form and make them open new tab
      const links = this.$el.querySelectorAll('.section-content-wrapper a');
      if (links && links.length > 0) {
        links.forEach((link) => {
          link.target = '_blank';
        });
      }
    },
    getType(keyToMatch) {
      let typeOfMatch;
      Object.keys(this.formSchema.properties).forEach((section) => {
        if (this.formSchema.properties[section].properties) {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (keyToMatch === key) {
              typeOfMatch = this.formSchema.properties[section].properties[key]['field-type'];
            }
          });
        }
      });

      return typeOfMatch;
    },
    requiresValidation(sectionKey, questionKey, groupKey) {
      if (this.formSchema) {
        const section = this.formSchema.properties[sectionKey];
        if (section && section.properties) {
          if (!groupKey) {
            const question = section.properties[questionKey];
            if (question && question.data) {
              return question.data.requireValidation;
            }
          } else {
            const group = section.properties[groupKey];
            if (group && group.properties) {
              const question = group.properties[questionKey];
              if (question && question.data) {
                return question.data.requireValidation;
              }
            }
          }
        }
      }
      return false;
    },
    isSectionDisabled(index) {
      return index > this.largestCompletedSection;
    },
    getRequiredSectionValidation() {
      let required = [];
      if (!this.formSchema) {
        return;
      }

      const sectionKey = this.getSchemaCurrentSectionKey();
      const sectionSchema = this.formSchema.properties[sectionKey];
      if (sectionSchema) {
        Object.keys(sectionSchema.properties).forEach((key) => {
          const schema = sectionSchema.properties[key];

          if (schema['field-type'] !== 'form-group') {
            // In case of other choice check if required
            if (key.indexOf('/Other') > -1) {
              const parentKey = key.replace('/Other', '');
              if (
                this.model[parentKey] === 'Other' &&
                sectionSchema.required &&
                sectionSchema.required.indexOf(parentKey) > -1
              ) {
                required.push(key);
              }
            } else if (sectionSchema.required && sectionSchema.required.indexOf(key) > -1) {
              required.push(key);
            }
          }
        });
      }

      return required;
    },
    onValidated(valid) {},
    onChangeState(value) {
      setTimeout(() => {
        if (!this.formSchema || !this.formSchema.properties) {
          return;
        }

        if (
          this.formSchema.properties[this.getSchemaCurrentSectionKey()] &&
          this.formSchema.properties[this.getSchemaCurrentSectionKey()].properties
        ) {
          Object.keys(this.formSchema.properties[this.getSchemaCurrentSectionKey()].properties).forEach((key) => {
            if (!document.getElementById(key)) {
              if (this.model[key]) {
                this.model = omit(this.model, key);
              }
            }
          });
          setTimeout(() => {
            Object.keys(this.formSchema.properties[this.getSchemaCurrentSectionKey()].properties).forEach((key) => {
              if (!document.getElementById(key)) {
                if (this.model[key]) {
                  this.model = omit(this.model, key);
                }
              }
            });
          }, 200);
        }
      }, 200);
    },
    closeForm() {
      if (this.form) {
        this.form.formSubmit = [];
        this.$router.push({ name: 'home' });
      } else {
        this.$emit('exitPreview');
      }
    },
    displaySelectedSection() {
      const selected = this.getSchemaCurrentSectionKey();
      this.$el.querySelectorAll('[index]').forEach((e) => (e.style.display = 'none'));
      if (this.$el.querySelector(`[id="#/properties/${selected}"]`)) {
        this.$el.querySelector(`[id="#/properties/${selected}"]`).style.display = 'block';
      }
    },
    hideSections() {
      this.$el.querySelectorAll('[index]').forEach((e) => (e.style.display = 'selected'));
      if (this.$el.querySelector(`[id="#/properties/${selected}"]`)) {
        this.$el.querySelector(`[id="#/properties/${selected}"]`).style.display = 'none';
      }
    },
    getSchemaCurrentSectionKey() {
      if (!this.formSchema.properties) {
        return;
      }
      return Object.keys(this.formSchema.properties).sort(
        (a, b) => this.formSchema.properties[a].data.order - this.formSchema.properties[b].data.order
      )[this.selectedSection];
    },
    setModelValuesForValidation(model) {
      Object.keys(this.formSchema.properties).forEach((section) => {
        if (this.formSchema.properties[section].properties) {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (this.formSchema.properties[section].properties[key]['field-type'] === fieldTypes.group) {
              const values = this.getRepeatableValues(key, section);
              if (values.length !== 0 || this.formSchema.properties[section].properties[key].minItems !== 1) {
                values.map((value) => {
                  Object.keys(value).map((key) => {
                    if (value[key] === '[object Object]') {
                      value[key] = model[key.split('.')[0]][key.split('.')[1]];
                    }
                  });
                });

                this.parsedModel[key] = values;
              } else {
                this.parsedModel[key] = [{}];
              }
            } else {
              // Normal case
              if (model[key]) {
                this.parsedModel[key] = this.convertToType(
                  this.formSchema.properties[section].properties[key].type,
                  model[key]
                );
              } else {
                delete this.parsedModel[key];
              }
            }
          });
        }
      });
    },
    checkAllowedChangeSection($event) {
      // Checks if the current section is valid before switching
      var sectionToSwitch = Number($event.currentTarget.value);
      var valid = this.validateCurrentSection();
      if (!valid && this.largestCompletedSection !== this.selectedSection) {
        this.valid = false;
        document.getElementsByClassName('select-section')[0].value = this.selectedSection;
      } else {
        this.valid = true;
        this.selectedSection = sectionToSwitch;
      }
    },
    showErrors(errors) {
      errors.forEach((error) => {
        let warning;
        //This display errors on group questions.
        if (error.keyword === 'const') {
          warning = document.getElementsByClassName(
            'warning-required-' + JSON.parse(error.dataPath.replace(/'/g, '"')).pop()
          )[0];
        }
        if (
          error.keyword === 'minItems' ||
          (error.keyword === 'required' && error.params.missingProperty.includes('group'))
        ) {
          if (error.keyword === 'minItems') {
            if (error.dataPath.includes('group')) {
              let group;
              if (
                /^[\],:{}\s]*$/.test(
                  error.dataPath
                    .replace(/'/g, '"')
                    .replace(/\\["\\\/bfnrtu]/g, '@')
                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
                )
              ) {
                group = error.keyword === 'minItems' ? JSON.parse(error.dataPath.replace(/'/g, '"')).pop() : 0;
              } else {
                group =
                  error.keyword === 'minItems'
                    ? error.dataPath
                        .replace(/'/g, '')
                        .split('][')[0]
                        .replace('[', '')
                    : 0;
              }
              this.formSchema.properties[this.getSchemaCurrentSectionKey()].properties[group].items.required.forEach(
                (required) => {
                  if (this.$el.getElementsByClassName('warning-required-' + required)[0]) {
                    this.$el.getElementsByClassName('warning-required-' + required)[0].style.display = 'block';
                  }
                }
              );
            } else {
              let questionId = JSON.parse(error.dataPath.replace(/'/g, '"')).pop();
              if (this.$el.getElementsByClassName('warning-required-' + questionId)[0]) {
                this.$el.getElementsByClassName('warning-required-' + questionId)[0].style.display = 'block';
              }
            }
          } else {
            warning = document.getElementsByClassName('warning-required-' + error.params.missingProperty)[
              error.dataPath
                .split('][')
                .pop()
                .slice(0, -1)
            ];
          }
        } else if (error.keyword === 'required') {
          warning = document.getElementsByClassName('warning-required-' + error.params.missingProperty)[0];
        } else if (error.keyword === 'pattern' || error.keyword === 'type' || error.keyword === 'enum') {
          //  warning-pattern-form-group/1.form-group/1/form-question/0
          let key = error.dataPath;
          if (key.indexOf('form-group') > -1) {
            key = key.split('[0]')[1];
          }

          key = key
            .replace(/'/g, '')
            .replace(/\[/g, '')
            .replace(/]/g, '')
            .replace(/"/g, '');

          warning = document.getElementsByClassName('warning-pattern-' + key)[0];
        } else if (error.keyword === 'minimum' || error.keyword === 'maximum') {
          let key = error.dataPath;
          let minHtml = `<i class="material-icons">cancel</i> Please enter numbers ${error.params.comparison} ${error.params.limit}`;
          let maxHtml = `<i class="material-icons">cancel</i> Please enter numbers ${error.params.comparison} ${error.params.limit}`;
          if (key.indexOf('form-group') > -1) {
            key = key.split('[0]')[1];
          }
          let questionId = JSON.parse(key.replace(/'/g, '"')).pop();

          warning = document.getElementsByClassName('warning-number-' + questionId)[0];
          if (error.keyword === 'minimum') {
            warning.innerHTML = minHtml;
          } else {
            warning.innerHTML = maxHtml;
          }
        }
        if (warning) {
          warning.style.display = 'block';
        }
      });
    },
    hideErrors(error) {
      let warnings = document.getElementsByClassName('alert-danger');
      for (var i = 0; i < warnings.length; i++) {
        if (warnings[i].style.display === 'block') {
          warnings[i].style.display = 'none';
        }
      }
    },
    validateCurrentSection() {
      let parsedModel = this.parsedModel;
      let schema = cloneDeep(this.formSchema.properties[this.getSchemaCurrentSectionKey()]);

      // Sections without questions are considered valid
      if (!schema.properties || Object.keys(schema.properties).length === 0) {
        return true;
      }

      schema.definitions = this.formSchema.definitions;
      schema.required = cloneDeep(this.formSchema.properties[this.getSchemaCurrentSectionKey()]).required
        ? cloneDeep(this.formSchema.properties[this.getSchemaCurrentSectionKey()]).required
        : [];
      if (this.formSchema.allOf) {
        schema.allOf = cloneDeep(this.formSchema.allOf).map((e) => {
          const result = {};
          result.if = Object.keys(e.if.properties)
            .filter((key) => {
              let section = e.if.properties[key];

              let value = parsedModel[Object.keys(section.properties)[0]];
              let valueToMatch = section.properties[Object.keys(section.properties)[0]].const;

              return value === valueToMatch;
            })

            .map((key) => {
              return e.if.properties[key];
            })[0];

          result.else = e.else.properties[this.getSchemaCurrentSectionKey()];
          result.then = e.then.properties[this.getSchemaCurrentSectionKey()];

          if (!result.else || !result.then) {
            return;
          }

          return result;
        });
        schema.allOf = schema.allOf.filter((allOf) => allOf);
        if (schema.allOf.length === 0) {
          delete schema.allOf;
        }
      }
      var validate = this.ajv.compile(schema);
      var valid = validate(this.parsedModel);
      if (schema.properties) {
        const groups = Object.keys(schema.properties).filter((e) => e.includes('group'));
        groups.forEach((group) => {
          if (schema.properties[group].properties) {
            const choices = Object.keys(schema.properties[group].properties).filter(
              (e) => schema.properties[group].properties[e]['field-type'] === fieldTypes.questionChoice
            );
            choices.forEach((choice) => {
              if (this.parsedModel[group]) {
                this.parsedModel[group].forEach((response, index) => {
                  if (response[choice] === 'Other' && !response[`${choice}/Other`]) {
                    valid = false;
                    if (!validate.errors) {
                      validate.errors = [];
                    }
                    validate.errors.push({
                      keyword: 'required',
                      dataPath: `['${group}'][${index}]`,
                      schemaPath: `#/properties/form-group~${group.split('/').pop()}${index}/items/required`,
                      params: {
                        missingProperty: `${choice}/Other`
                      },
                      message: `should have required property '${choice}/Other'`
                    });
                  }
                });
              }
            });
          }
        });
      }

      this.checkValidationAddressDoublesOther(this.model);
      // valid = validation of current schema.
      // this.valid = custom validation of address/requireValidationInputs and choices other option.
      if (valid && this.valid) {
        this.valid = true;
        this.submitInvalid = false;
      } else {
        this.valid = false;
        this.submitInvalid = true;
      }
      this.hideErrors();
      if (validate.errors) {
        console.error('validate.errors', validate.errors);
        this.showErrors(validate.errors);
      }

      return this.valid;
    },
    goToPreviousSection() {
      var valid = this.validateCurrentSection();
      if (!valid && this.largestCompletedSection !== this.selectedSection) {
        this.valid = false;
        document.getElementsByClassName('select-section')[0].value = this.selectedSection;
      } else {
        this.valid = true;
        this.selectedSection--;
      }
    },
    handleNextSection() {
      let selectors = this.$el.querySelectorAll('form-address-field input');

      // Transform model data to its respective type
      this.setModelValuesForValidation(this.model);
      // Check if AllOf parts are hidden based on selected options
      this.clearModelBasedOnAllOf();

      // Use Ajv to validate sections of schemas
      var valid = this.validateCurrentSection();
      if (!valid) {
        return;
      }

      if (this.selectedSection !== this.sections - 1) {
        this.goToNextSection();
      } else {
        this.submitForm();
      }
    },
    goToNextSection() {
      this.valid = true;
      this.submitInvalid = false;
      this.selectedSection++;
      if (this.largestCompletedSection <= this.selectedSection) {
        this.largestCompletedSection = this.selectedSection;
      }

      this.$el.getElementsByClassName('screen')[0].scrollIntoView();
      const displayedSection = this.$el.getElementsByClassName('section-container');
      setTimeout(() => {
        for (let element of displayedSection) {
          if (element.style.display === 'block') {
            const elementToFocus = element.querySelectorAll('input')[0];
            if (elementToFocus) {
              elementToFocus.focus();
            }
          }
        }
      }, 500);
    },
    cleanLocalFormValues(formId) {
      let formsStoredData = this.localFormStorageOnboardee;
      if (formsStoredData) {
        formsStoredData = formsStoredData.filter((formStoredData) => formStoredData.id !== formId);
      } else {
        formsStoredData = [];
      }
      window.localStorage.setItem(this.keyLocalFormStorage, JSON.stringify(formsStoredData));
    },
    setRepeatebleValues() {
      if (this.formSchema) {
        Object.keys(this.formSchema.properties).forEach((section) => {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (
              this.formSchema.properties[section].properties[key]['field-type'] === fieldTypes.group &&
              !this.formSchema.properties[section].properties[key].maxItems
            ) {
              const values = this.getRepeatableValues(key, section);
              this.repeatableValuesModel[key] = values;
            }
          });
        });
      }
    },
    saveLocalFormValues() {
      if (!this.form || this.form.formSubmit || this.savedLocally) {
        return;
      }

      const formData = {
        id: this.form.id,
        values: this.model
      };

      formData.values = { ...formData.values, ...this.repeatableValuesModel };
      let formsStoredData = this.localFormStorageOnboardee;
      if (!formsStoredData || (formsStoredData && formsStoredData.length === 0)) {
        formsStoredData = [formData];
      } else {
        let replaced = false;
        formsStoredData = formsStoredData.map((formStoredData) => {
          if (formStoredData.id === formData.id) {
            formStoredData = formData;
            replaced = true;
          }
          return formStoredData;
        });

        if (!replaced) {
          formsStoredData.push(formData);
        }
      }

      this.savedLocally = true;
      window.localStorage.setItem(this.keyLocalFormStorage, JSON.stringify(formsStoredData));
    },
    getFormNames(formId) {
      return this.dashboardData.formsV2ToDo
        .filter((formV2ToDo) => formV2ToDo.formId === formId)
        .map((form) => form.formName);
    },
    submitForm() {
      // Admin preview submit
      if (!this.form) {
        this.formFinished = true;
        return;
      }

      // Onboardee submit
      let formatedModel = {};
      Object.keys(this.formSchema.properties).forEach((section) => {
        formatedModel[section] = {};
        if (this.formSchema.properties[section].properties) {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (this.formSchema.properties[section].properties[key]['field-type'] === fieldTypes.group) {
              // Group and repeatables

              const values = this.getRepeatableValues(key, section);
              if (values.length !== 0 || this.formSchema.properties[section].properties[key].minItems !== 1) {
                values.map((value) => {
                  Object.keys(value).map((key) => {
                    if (value[key] === '[object Object]') {
                      value[key] = this.model[key.split('.')[0]][key.split('.')[1]];
                    }
                  });
                });
                formatedModel[section][key] = values;
              } else {
                formatedModel[section][key] = [{}];
              }
            } else {
              // Normal case
              if (this.parsedModel[key]) {
                formatedModel[section][key] = this.convertToType(
                  this.formSchema.properties[section].properties[key].type,
                  this.parsedModel[key]
                );
              }
            }
          });
        }
      });

      // Todo load the correct locale
      const moduleId = this.$route.params.module || this.$route.params.moduleId;
      const learningPageId = this.$route.params.id || this.$route.params.learningpageId;

      const formData = {
        formversionid: this.form.versions[this.form.versions.length - 1].id,
        LearningPageId: learningPageId,
        LearningPageModuleId: moduleId,
        Data: formatedModel,
        localeCode: 'en-US'
      };

      const formId = this.form.id;
      const formName = this.getFormNames(this.form.id);
      const user = JSON.parse(window.localStorage.getItem('onboarding_user'));
      Vue.prototype.$http
        .put(
          `/dashboard/v2/forms/${moduleId}/${learningPageId}/${formId}/formsubmit?languageId=${user.languageId}`,
          formData
        )
        .then((response) => {
          if (!response.data.errors) {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.successSubmitForm'),
              position: 'bottom-right'
            });
            this.formFinished = true;
            this.cleanLocalFormValues(this.form.id);

            //Planhat form submission event succeeded
            this.track('tal_form_submission_succeeded', formName[0]);
            setTimeout(() => {
              const closeForm = this.$el.querySelector('.close-form');
              closeForm.focus();
            }, 100);
          }

          if (response.data.errors) {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.errorSubmitForm') + ': ' + response.data.errors[0].message,
              position: 'bottom-right'
            });
            //Planhat form submission event failed
            this.track('tal_form_submission_failed', formName[0]);
          }
        });
    },
    mapObjectSchema(schema, group) {
      let result = {};
      Object.keys(schema).forEach((e) => {
        const key = e.split('.').pop();
        if (this.model[group][key]) {
          result[e] = this.convertToType(schema[e].type, this.model[group][key]);
        }
      });
      return result;
    },
    convertToType(type, value) {
      switch (type) {
        case 'boolean':
          if (typeof value === 'boolean') {
            return value;
          }
          return value === 'true';
        case 'number':
        case 'integer':
          return Number(value);
        case 'object':
          if (this.IsJsonString(value)) {
            return JSON.parse(value);
          } else {
            return value;
          }
        case 'array':
          if (this.IsJsonString(value)) {
            return JSON.parse(value);
          } else if (value && !Array.isArray(value)) {
            return value.split(',');
          } else {
            return value;
          }
        default:
          return value;
      }
    },
    addDoubleValidationWarning(key) {
      if (!document.getElementById('alert/' + key)) {
        var node = document.createElement('div');
        node.id = 'alert/' + key;
        node.classList.add('alert', 'alert-danger', 'showAlways');
        node.innerHTML = '<i class="material-icons">cancel</i> This does not match the previous field';
        if (document.getElementById(key)) {
          document.getElementById(key).parentNode.appendChild(node);
        }
      }
    },
    removeDoubleValidationWarning(key) {
      var element = document.getElementById('alert/' + key);
      if (!element) {
        return;
      }
      element.parentNode.removeChild(element);
    },
    removeValidOtherOptions(key) {
      var element = document.getElementById('invalid-other/' + key);
      if (!element) {
        return;
      }
      element.parentNode.removeChild(element);
    },
    checkValidDoubleValidationFields(values) {
      var isValid = true;
      Object.keys(values).forEach((key) => {
        if (key.indexOf('form-group') > -1) {
          const groupKey = values[key];
          Object.keys(values[key]).forEach((questionGroup) => {
            let confirmKey = questionGroup;
            if (questionGroup.indexOf('confirm') < 0) {
              confirmKey = questionGroup + '/confirm';
              confirmKey = confirmKey.slice(0, confirmKey.indexOf('/', 11)) + '.' + confirmKey;
            }

            const confirm = document.getElementById(confirmKey);
            if (groupKey[questionGroup] && confirm && !confirm.value) {
              isValid = false;
            }

            if (questionGroup.indexOf('confirm') > -1) {
              var parentKey = questionGroup.replace('/confirm', '');
              parentKey = parentKey.slice(0, parentKey.indexOf('/', 11)) + '.' + parentKey;
              if (groupKey[questionGroup] !== groupKey[questionGroup.replace('/confirm', '')]) {
                isValid = false;
              }
              confirmKey = confirmKey.slice(0, confirmKey.indexOf('/', 11)) + '.' + confirmKey;
            }

            if (!isValid) {
              this.addDoubleValidationWarning(confirmKey);
            } else {
              this.removeDoubleValidationWarning(confirmKey);
            }
          });
        } else {
          let confirmKey = key;
          if (key.indexOf('confirm') < 0) {
            confirmKey = key + '/confirm';
          }

          const confirm = document.getElementById(confirmKey);
          if (values[key] && confirm && !confirm.value) {
            isValid = false;
          }

          if (key.indexOf('confirm') > -1) {
            var parentKey = key.replace('/confirm', '');
            if (values[key] !== values[parentKey]) {
              isValid = false;
            }
          }

          if (!isValid) {
            this.addDoubleValidationWarning(confirmKey);
          } else {
            this.removeDoubleValidationWarning(confirmKey);
          }
        }
      });

      return isValid;
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (error) {
        return false;
      }
      return true;
    },
    checkValidOtherOptions(values) {
      var isValid = true;
      let warning;
      Object.keys(values).forEach((key) => {
        if (values[key] && typeof values[key] === 'string' && values[key].indexOf('Other') > -1) {
          setTimeout(() => {
            const questionWrapper = document.getElementById(key);
            if (questionWrapper) {
              warning = questionWrapper.getElementsByClassName(`warning-required-${key}/Other`)[0];

              if (
                document.querySelector(`input[id="${key}/Other"]`).value === '' ||
                document.querySelector(`input[id="${key}/Other"]`).value === undefined
              ) {
                if (warning) {
                  warning.style.display = 'block';
                }
              } else if (warning) {
                warning.style.display = 'none';
              }
            }
          }, 10);
        } else {
          const questionWrapper = document.getElementById(key);
          if (questionWrapper) {
            warning = questionWrapper.getElementsByClassName(`warning-required-${key}/Other`)[0];

            if (warning) {
              warning.style.display = 'none';
            }
          }
        }
      });

      return isValid;
    },
    checkValidAddress(values) {
      let isValid = true;
      const schema = this.formSchema.properties[this.getSchemaCurrentSectionKey()];
      Object.keys(values).forEach((key) => {
        let value = values[key];
        // Dont continue validation if not required
        if (schema.required && schema.required.indexOf(key) === -1) {
          return;
        }

        // In case of group
        if (Array.isArray(value)) {
          value = value[0];
          if (value) {
            Object.keys(value).forEach((key) => {
              let groupQuestion = value[key];
              isValid = this.checkShowHideAddressError(groupQuestion, key);
            });
          }
        } else {
          isValid = this.checkShowHideAddressError(value, key);
        }
      });

      return isValid;
    },
    checkShowHideAddressError(value, key) {
      let isValid = true;

      if (typeof value === 'object' && !Array.isArray(value)) {
        let warning = document.getElementsByClassName('warning-required-' + key)[0];
        if (
          value.hasOwnProperty('address_line_1') &&
          value['address_line_1'] &&
          value.hasOwnProperty('city') &&
          value['city'] &&
          value.hasOwnProperty('postal_code') &&
          value['postal_code'] &&
          value.hasOwnProperty('country_code') &&
          value['country_code'] &&
          warning
        ) {
          warning.style.display = 'none';
        } else if (warning) {
          warning.style.display = 'inherit';
          isValid = false;
        }
      }

      return isValid;
    },
    getRepeatableValues(key, section) {
      const arrayGroup = [];
      if (this.$el) {
        this.$el.querySelectorAll(`[id='${key}'] .group-content-wrapper`).forEach((groupSelector) => {
          const result = {};
          if (
            this.formSchema.properties[section].properties &&
            this.formSchema.properties[section].properties[key].properties
          ) {
            Object.keys(this.formSchema.properties[section].properties[key].properties).forEach((questionKey) => {
              const questionElement =
                groupSelector.querySelector(`input[id='${questionKey}']`) ||
                groupSelector.querySelector(`textarea[id='${questionKey}']`);
              if (!questionElement) {
                return;
              }
              let value = questionElement.value;
              if (isEmpty(value)) {
                value = undefined;
              }
              result[questionKey] = this.convertToType(
                this.formSchema.properties[section].properties[key].properties[questionKey].type,
                value
              );
            });
          }

          if (!isEmpty(result)) {
            arrayGroup.push(result);
          }
        });
      }
      return arrayGroup;
    },
    clearModelBasedOnAllOf() {
      if (this.formSchema.allOf) {
        let modelToDelete = {};
        let count = 0;
        const currentSection = this.getSchemaCurrentSectionKey();
        this.formSchema.allOf.forEach((allOf) => {
          Object.keys(allOf.if.properties).forEach((keySection) => {
            if (keySection === currentSection) {
              count++;
              Object.keys(allOf.if.properties[keySection].properties).forEach((keyQuestion) => {
                const ifValue = allOf.if.properties[keySection].properties[keyQuestion].const;
                if (this.parsedModel[keyQuestion] && this.parsedModel[keyQuestion] !== ifValue) {
                  modelToDelete[keyQuestion] = allOf.then.properties;
                }
              });
            }
          });
        });

        if (Object.keys(modelToDelete).length === count && Object.keys(modelToDelete).length > 0) {
          const questionKey = Object.keys(modelToDelete)[0];
          const sectionKey = Object.keys(modelToDelete[questionKey])[0];
          const required = modelToDelete[questionKey][sectionKey].required;
          required.forEach((key) => {
            if (this.parsedModel[key]) {
              delete this.parsedModel[key];
            }
          });
        }
      }
    },
    isRepeatableGroup(keyToMatch) {
      let isRepeatableGroup = false;
      Object.keys(this.formSchema.properties).forEach((section) => {
        if (this.formSchema.properties[section].properties) {
          Object.keys(this.formSchema.properties[section].properties).forEach((key) => {
            if (key === keyToMatch) {
              isRepeatableGroup = this.formSchema.properties[section].properties[key].data.repeatable;
            }
          });
        }
      });
      return isRepeatableGroup;
    },
    checkValidationAddressDoublesOther(newVal) {
      const validDoubleCheck = this.checkValidDoubleValidationFields(newVal);
      const validOtherOptions = this.checkValidOtherOptions(newVal);
      const validAddress = this.checkValidAddress(this.parsedModel);
      this.valid = validDoubleCheck && validOtherOptions && validAddress;
    }
  },
  beforeDestroy() {
    this.saveLocalFormValues();
    clearInterval(this.repeatableInterval);
  },
  watch: {
    model(newVal) {
      this.setModelValuesForValidation(this.model);

      this.checkValidationAddressDoublesOther(newVal);

      if (this.submitInvalid) {
        this.validateCurrentSection();
      }
    },
    selectedSection() {
      this.displaySelectedSection();
    }
  },
  computed: {
    ...mapGetters('editor', ['previewClass']),
    ...mapGetters('formBuilder', ['uiSchema', 'schema']),
    ...mapGetters('loading', ['loading']),
    ...mapGetters('dashboard', ['onboardeeId', 'dashboardData']),
    keyLocalFormStorage() {
      return `localFormStorageOnboardee${this.onboardeeId}`;
    },
    localFormStorageOnboardee() {
      return JSON.parse(localStorage.getItem(this.keyLocalFormStorage));
    },
    sections() {
      if (this.formSchema && this.formSchema.properties) {
        return Object.keys(this.formSchema.properties).length;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss">
button span .p-4 {
  padding: 0 !important;
}

.btn[disabled] .v-beat {
  vertical-align: middle;
}

.form-control.confirm {
  margin-top: 8px;
}

.showAlways {
  display: block !important;
}

.alert.alert-danger {
  display: none;
  margin-top: 8px;
  background-color: rgba(208, 2, 27, 0.1);
  border: 1px solid #d0021b;
  color: #666;
  font-weight: bold;

  .material-icons {
    color: #d0021b;
    vertical-align: middle;
    margin-right: 16px;
  }
}

.alert.alert-danger.alert-other {
  display: block;
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
@import '@forms/forms.scss';

.learning-page-content .screen {
  min-width: 100%;
}

.editor-page {
  margin: 0 15px;
  padding: 6px 24px;
}

.breadcrums-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .section {
    width: 23px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid $color-darkgreyblue;
    display: inline-block;
    margin: 0 4px;
    background-color: white;
  }

  select {
    color: $color-darkgreyblue;
    line-height: 1;
    padding: 0.4em;
    width: 80px;
    box-sizing: border-box;
    margin: 0 4px;
    border: 1px solid $color-darkgreyblue;
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
  select::-ms-expand {
    display: none;
  }
  select:hover {
    border-color: #888;
  }
  select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 1px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 1px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  select option {
    font-weight: normal;
  }
}
.form-group button {
  background-color: white;
}
.formFinished {
  text-align: center;

  button {
    border-radius: 4px;
    text-decoration: none !important;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    color: #666666;
    border: 1px solid #dddddd;
    padding: 8px;
    display: inline-block;
    margin-bottom: 8px;
    cursor: pointer;
  }

  button:hover {
    background-color: #f9f9f9;
  }
}
</style>
